export const TOPIC_BASE_FRAGMENT = `
  fragment topicBaseFragment on Topic {
    uid
    id
    schemaCode
    name
    autoFollow
    description
    _isFollowed(myUid: "%authUser%")
    _followerCount
    organizerOnlyPosting
  }
`;
